<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <!--加工計画修正・削除画面-->
      <NavBar tittle="label.lbl_distributionProcessingPlanUpdateDelete" />
      <v-form ref="form" lazy-validation>
        <!-- 検索項目-->
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete-supplier first-search-item" style="width: 400px">
              <div>
                <v-text-field
                  dense
                  readonly
                  v-model="suppliersNm"
                  :label="$t('label.lbl_supplier')"
                  class="txt-single"
                  persistent-hint
                  :hint="setSrhSupplier()"
                  margin-bottom="0"
                  pa-3
                ></v-text-field>
              </div>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 伝票No-->
            <div
              v-if="deleteControlFlg == false"
              class="search-textbox-voucherNo"
              style="width: 300px"
            >
              <v-text-field
                outlined
                dense
                v-model="voucherNo"
                maxlength="50"
                :label="$t('label.lbl_voucherNo')"
                class="txt-singles"
                :rules="[rules.inputRequired]"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <div v-else class="search-textbox-voucherNo" style="width: 300px">
              <v-text-field
                outlined
                dense
                v-model="voucherNo"
                maxlength="50"
                :label="$t('label.lbl_voucherNo')"
                class="txt-singles"
                :rules="[rules.inputRequired]"
                clear-icon="mdi-close-circle"
                readonly
              />
            </div>
            <span class="require asterisk-spacer">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 作業日-->
            <div v-if="deleteControlFlg == false" class="date-style">
              <v-text-field
                dense
                outlined
                class="txt-singles date-style"
                v-model="planDay"
                :label="$t('label.lbl_planDay')"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
              />
            </div>
            <div v-else class="date-style">
              <v-text-field
                :readonly="true"
                dense
                outlined
                class="txt-singles date-style"
                v-model="planDay"
                :label="$t('label.lbl_planDay')"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
              />
            </div>
            <span class="require asterisk-spacer">*</span>
            <div v-if="deleteControlFlg == false">
              <div class="calender-btn-area" style="float: left">
                <!-- 作業日カレンダー-->
                <v-menu
                  v-model="planDayMenu"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text x-small>
                      <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="planDayCal"
                    @input="planDayMenu = false"
                    max="2099-12-31"
                  ></v-date-picker>
                </v-menu>
                <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                  <!-- 前日-->
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                  <!-- 次日-->
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷先-->
            <div v-if="deleteControlFlg == false" class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="shippingSelected"
                :items="shippingList"
                :label="$t('label.lbl_shippingTo')"
                :hint="setSrhArrival()"
                class="txt-singles"
                persistent-hint
                @change="changeArrival"
                :search-input.sync="getShippingSelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <div v-else class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="shippingSelected"
                :items="shippingList"
                :label="$t('label.lbl_shippingTo')"
                :hint="setSrhArrival()"
                class="txt-singles"
                persistent-hint
                @change="changeArrival"
                :search-input.sync="getShippingSelected"
                :readonly="true"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 品質区分 -->
            <div v-if="deleteControlFlg == false" class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="arrivalStatusSelected"
                :items="qualityAutoCompleteList"
                :label="$t('label.lbl_qualityDiv')"
                persistent-hint
                margin-bottom="0"
                pa-3
                :readonly="deleteControlFlg"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <div v-else class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="arrivalStatusSelected"
                :items="qualityAutoCompleteList"
                :label="$t('label.lbl_qualityDiv')"
                persistent-hint
                margin-bottom="0"
                pa-3
                :readonly="true"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 責任区分-->
            <v-btn-toggle
              v-model="isRes"
              mandatory
              color="blue darken-2"
              class="toggle black--text"
            >
              <v-btn
                mandatory
                class="res-btn"
                v-for="item in blameDivList"
                :key="item.value"
                :value="item.value"
                small
                v-bind:disabled="isDisabledBlame"
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>
          </v-row>
          <v-row>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 有償/無償-->
            <div style="float: left">
              <v-btn-toggle
                mandatory
                v-model="isPaid"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
                :readonly="true"
              >
                <v-btn
                  class="paid-btn"
                  v-for="item in freeCostDivList"
                  :key="item.value"
                  :value="item.value"
                  small
                  v-bind:disabled="deleteControlFlg == true"
                >
                  {{ item.text }}</v-btn
                >
              </v-btn-toggle>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 無償理由選択プルダウン-->
            <div v-if="deleteControlFlg == false" class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                class="list-single"
                v-model="freeReasonSelected"
                :items="freeReasonList"
                v-bind:disabled="isDisabledFreeReason"
                :label="$t('label.lbl_freeReason')"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 無償理由選択プルダウン-->
            <div v-else class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                class="list-single"
                v-model="freeReasonSelected"
                :items="freeReasonList"
                v-bind:disabled="isDisabledFreeReason"
                :label="$t('label.lbl_freeReason')"
                :readonly="true"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <v-col class="txt-remarks">
              <!-- 備考 -->
              <div v-if="deleteControlFlg == false">
                <v-text-field
                  dense
                  outlined
                  class="txt-singles"
                  v-model="remarksTextbox"
                  :label="$t('label.lbl_remarksTextbox')"
                  clear-icon="mdi-close-circle"
                />
              </div>
              <div v-else>
                <v-text-field
                  :readonly="true"
                  dense
                  outlined
                  class="txt-singles"
                  v-model="remarksTextbox"
                  :label="$t('label.lbl_remarksTextbox')"
                  clear-icon="mdi-close-circle"
                />
              </div>
            </v-col>
          </v-row>

          <v-row>
            <div class="search-row-exeBtn">
              <div class="btn-area" style="float: left">
                <!-- 戻るボタン -->
                <v-btn class="other-btn" v-on:click="backbtn">
                  {{ $t("btn.btn_back") }}
                </v-btn>

                <!-- 追加ボタン -->
                <v-btn
                  id="other-btn"
                  class="other-btn"
                  value="1"
                  @click="add"
                  :disabled="isDoAddButton || deleteControlFlg == true"
                >
                  {{ $t("btn.btn_add") }}
                </v-btn>
              </div>
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <div class="btn-area">
                <!-- 修正登録ボタン-->
                <v-btn
                  class="post-btn"
                  :disabled="deleteControlFlg == true"
                  value="1"
                  v-on:click="planAddCheck"
                >
                  {{ $t("btn.btn_fixinsert") }}
                </v-btn>
              </div>
            </div>
          </v-row>
          <!-- 戻るボタンを押下時出現ダイアログ -->
          <v-dialog v-model="updateDialogMessage" :max-width="800">
            <v-card>
              <v-card-title class="blue-grey lighten-3" primary-title>
                {{ $t("btn.btn_ok") }}
              </v-card-title>
              <v-card-text class="pa-4">
                <p>{{ backMessage }}</p>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="updateOk"> OK </v-btn>
                <v-btn v-if="errorFlg" @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-form>
      <!-- データテーブル（項目） -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="600px"
          >
            <!-- 品番/品名 -->
            <template v-slot:[`item.productCd`]="{ item, index }">
              <div id="table-productCd" v-if="deleteControlFlg == true">
                {{ item.productHint }}
              </div>
              <div v-else>
                <!-- 商品コード/品名-->
                <v-autocomplete
                  v-model="item.productCd"
                  :items="item.productList"
                  @change="(event) => changeProductNm(event, item, index)"
                  :hint="checkProductCd(item, index)"
                  persistent-hint
                  :search-input.sync="item.search"
                  dense
                  :ref="'cursorProcd' + index"
                  :rules="[
                    $inputRules.isRequiredRow(headerItems, item),
                    $inputRules.isCreatedRowRequired(item.outListSubSid, item.productCd),
                  ]"
                  :error-messages="item.requiredItemCheckMsg"
                >
                  <option disabled value="item.productCd"></option>
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                </v-autocomplete>
              </div>
            </template>
            <!-- ロット-->
            <template v-slot:[`item.lot`]="{ item, index }">
              <div v-if="deleteControlFlg == true">
                {{ item.lot }}
              </div>
              <div v-else id="table-lot">
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.lot"
                  maxlength="100"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'lotCheck' + index"
                />
              </div>
            </template>

            <!-- S/N -->
            <template v-slot:[`item.serial`]="{ item, index }">
              <v-text-field
                outlined
                dense
                maxlength="32"
                v-model="item.serial"
                class="text-box"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'lotCheck' + index"
                :disabled="item.serialFlg != '1'"
              />
            </template>

            <!-- 期限日-->
            <template v-slot:[`item.dueDate`]="{ item, index }">
              <v-text-field
                outlined
                dense
                maxlength="8"
                v-model="item.dueDate"
                class="text-box"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'lotCheck' + index"
                :rules="[rules.isNumber]"
                :disabled="item.dueDateFlg != '1'"
                @change="deletedueDateOtherChar(item)"
              />
            </template>

            <!-- 付属キー1 -->
            <template v-slot:[`item.lotSubkey1`]="{ item, index }">
              <v-text-field
                outlined
                dense
                v-model="item.lotSubkey1"
                maxlength="20"
                class="text-box"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'lotCheck' + index"
                :disabled="item.lotSubkey1Flg != '1'"
              />
            </template>

            <!-- 付属キー2 -->
            <template v-slot:[`item.lotSubkey2`]="{ item, index }">
              <v-text-field
                outlined
                dense
                v-model="item.lotSubkey2"
                maxlength="20"
                class="text-box"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'lotCheck' + index"
                :disabled="item.lotSubkey2Flg != '1'"
              />
            </template>

            <!-- 予定数量 -->
            <template v-slot:[`item.scheduleQuantity`]="{ item, index }">
              <div v-if="deleteControlFlg == true">
                {{ item.scheduleQuantity }}
              </div>
              <div v-else id="table-scheduleQuantity">
                <v-text-field
                  outlined
                  dense
                  v-model="item.scheduleQuantity"
                  class="input-number"
                  @change="changeScheduleQuantity(item, index)"
                  @click="clickPop(item.scheduleQuantity)"
                  persistent-hint
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheckPlan,
                    rules.limitNumLength,
                    $inputRules.isRequiredRow(headerItems, item),
                    (input) => {
                      let result = checkMaxAmount(input, item.productCd, item.productList);
                      return result;
                    },
                  ]"
                  :ref="'scheduleQuantityCheck' + index"
                />
              </div>
            </template>

            <!-- 収容数 -->
            <template v-slot:[`item.capacityQty`]="{ item, index }">
              <div v-if="deleteControlFlg == true">
                {{ item.capacityQty }}
              </div>
              <div v-else id="table-capacityQty">
                <v-text-field
                  outlined
                  dense
                  v-model="item.capacityQty"
                  class="input-number"
                  @change="changeCapacityQty(item, index)"
                  @click="clickPop(item.capacityQty)"
                  persistent-hint
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'capacityQtyCheck' + index"
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheckPlan,
                    rules.limitNumLength,
                  ]"
                />
              </div>
            </template>

            <!-- 梱包数 -->
            <template v-slot:[`item.numberOfPackages`]="{ item, index }">
              <div v-if="deleteControlFlg == true">
                {{ item.numberOfPackages }}
              </div>
              <div v-else id="table-numberOfPackages">
                <v-text-field
                  outlined
                  dense
                  v-model="item.numberOfPackages"
                  class="input-number"
                  @change="changeNumberOfPackages(item, index)"
                  @click="clickPop(item.numberOfPackages)"
                  persistent-hint
                  :ref="'numberOfPackagesCheck' + index"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheckPlan,
                    rules.limitNumLength,
                    $inputRules.isRequiredRow(headerItems, item),
                  ]"
                />
              </div>
            </template>

            <!-- 子品番詳細 -->
            <template v-slot:[`item.materials`]="{ item }">
              <div id="table-MaterialsDialog">
                <v-btn small @click="openSelectMaterialsDialog(item)" text>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>

            <!-- 削除-->
            <template v-slot:[`item.deleteData`]="{ item }">
              <div v-if="deleteControlFlg == false" id="table-deleteData">
                <v-btn small @click="deleteItem(item)" text>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-form>
      <v-container fluid style="padding-top: 0px; height: 38px">
        <!--ボタン領域-->
        <v-row>
          <div class="search-row-exeBtn">
            <!--合計数量領域-->
            <div style="float: left; display: flex; align-items: center; width: 60%">
              <div
                style="margin-left: 80%; font-weight: bold; font-size: large; border-bottom: double"
              >
                <!-- 合計数量-->
                {{ $t("label.lbl_totalNum") + ":    " + this.totalCount }}
              </div>
            </div>
          </div>
        </v-row>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :changeFlag="infoDialog.changeFlag"
        :distProcPlanEditFlg="infoDialog.distProcPlanEditFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :okAction="infoDialog.okAction"
      />
      <!-- 資材確認モーダル -->
      <v-dialog
        v-model="selectMaterialsDialogIsShow"
        :max-width="1000"
        persistent=""
        no-click-animation
        scrollable
      >
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_materials") }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <!--品番品名-->
                <v-col>
                  <v-text-field
                    dense
                    v-model="productCdAndName"
                    :label="$t('label.lbl_productCnCd')"
                    readonly
                  ></v-text-field>
                </v-col>

                <!--付属品番-->
                <v-col>
                  <v-text-field
                    dense
                    v-model="accessoryNumber"
                    :label="$t('label.lbl_accessoryCd')"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <!--出荷先-->
                <v-col>
                  <v-text-field
                    dense
                    v-model="shippingAddress"
                    :label="$t('label.lbl_destination')"
                    readonly
                  ></v-text-field>
                </v-col>

                <!--親数量-->
                <v-col>
                  <v-text-field
                    dense
                    v-model="quantity"
                    :label="$t('label.lbl_quantity')"
                    readonly
                    class="number-align-right"
                  >
                  </v-text-field>
                </v-col>

                <!--梱包数-->
                <v-col>
                  <v-text-field
                    dense
                    v-model="packagingQuantity"
                    :label="$t('label.lbl_numberOfPackages')"
                    readonly
                    class="number-align-right"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <div>
                  <div v-if="message">
                    <!--検索結果表示-->
                    <div
                      class="text-label"
                      style="color: red; float: left; margin-top: 0px; padding-bottom: 0px"
                    >
                      <span>※{{ message }}</span>
                    </div>
                  </div>
                </div>
              </v-row>
            </v-container>

            <v-form ref="processedList" lazy-validation>
              <v-container fluid>
                <v-data-table
                  id="materialsListData"
                  fixed-header
                  disable-filtering
                  disable-sort
                  :headers="headers"
                  :items="materials"
                  :hide-default-footer="true"
                  disable-pagination
                  height="300px"
                  class="overflow-hidden"
                >
                  <!--No-->
                  <template v-slot:[`item.no`]="{ item }">
                    <span>{{ item.no }}</span>
                  </template>

                  <!--資材名-->
                  <template v-slot:[`item.material`]="{ item }">
                    <span>{{ item.material }}</span>
                  </template>

                  <!--資材数量-->
                  <template v-slot:[`item.quantity`]="{ item }">
                    <div v-if="item.colorFlg == true" style="text-align: right; color: red">
                      {{ item.quantity }}
                    </div>
                    <div v-else style="text-align: right">
                      {{ item.quantity }}
                    </div>
                  </template>
                </v-data-table>
              </v-container>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!--ボタン領域-->
              <div class="search-row-exeBtn">
                <!-- 閉じるボタン領域 -->
                <div class="btn-search-area" style="float: right">
                  <v-btn class="other-btn" @click="closeDialog">
                    {{ $t("btn.btn_close") }}
                  </v-btn>
                </div>
              </div>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import NavBar from "../../components/NavBar.vue";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { i18n } from "../../lang/lang.js";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";

export default {
  name: appConfig.SCREEN_ID.P_TMP_904,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // 資材モーダル：資材数量桁数チェック
    message: "",
    // トータル数量
    totalCount: 0,
    // 加工日、有償無償トグル等の編集可フラグ
    delConfirm: true,

    // 追加ボタン非活性フラグ
    isDoAddButton: false,
    focusIdx: 0,

    // 修正登録ボタン非活性フラグ
    ableConfirm: true,

    // 品質区分
    qualityAutoCompleteList: [],
    arrivalStatusSelected: "01",

    // 責任区分
    blameDivList: [],
    isDisabledBlame: true,
    isRes: "01",
    isResBadValue: "02",
    isResBadCount: 0,

    // 検索条件表示用
    // 取引先
    suppliersSelected: "",
    // 取引先名
    suppliersNm: "",
    // 加工日
    planDay: "",
    // 加工日カレンダー
    planDayMenu: "",
    // 伝票No
    voucherNo: "",
    // 出荷先
    shippingSelected: "",
    getShippingSelected: "",
    productHintArrival: "",
    // 出荷No
    shippingNo: "",
    // 有償 / 無償
    isPaid: "",
    // 無償理由
    freeReasonSelected: "",
    freeReasonList: [],
    // 備考
    remarksTextbox: "",
    // 伝票削除フラグ
    deleteFlg: false,
    defaultItem: {
      productNm: "",
      productCd: "",
      productHint: "",
      accessoryCd: "",
      scheduleQuantity: "",
      capacityQty: "",
      numberOfPackages: "",
      lot: "",
      productList: [],
      isNew: false,
      search: "",
    },
    //前画面から受け取る値
    planDayTo: "",
    planningSection: "",
    allocationResult: "",
    arrCreatedDateFrom: "",
    inOutListSub: "",
    inOutScheduleDate: "",
    inOutListNo: "",
    processDiv: "",
    carrierCompName: "",
    deliveryTraderName: "",
    screenName: "",
    // 初期データ
    defaultData: [],

    // 入力項目バリデーション用ルール
    rules: {
      // 日付フォーマットチェック
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      // 必須チェック
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      // 文字数の制約
      limitNumLength: (value) =>
        value == null ||
        String(value).replaceAll(",", "").length < 10 ||
        i18n.tc("check.chk_limitNumLength"),
      // 数値チェック
      isNumber: (value) =>
        value == null ||
        value == "" ||
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
      // 整数チェック
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      // 1以上を許容
      isRangeCheckPlan: (value) =>
        value == null ||
        value == "" ||
        1 <= Number(commonUtil.zen2han(value).replaceAll(",", "")) ||
        i18n.tc("check.chk_inputMore"),
    },

    // 一覧表示用
    // 表示一覧データ
    inputList: [],
    // 資材モーダルヘッダ表示用:品番
    productCd: "",
    // 資材モーダルヘッダ表示用:品番・品名
    productCdAndName: "",
    // 資材モーダルヘッダ表示用:出荷先
    shippingAddress: "",
    // 資材モーダルヘッダ表示用:付属品番
    accessoryNumber: "",
    // 資材モーダルヘッダ表示用:数量
    quantity: "",
    // 資材モーダルヘッダ表示用:梱包数
    packagingQuantity: "",
    // 資材モーダルヘッダー表示
    // 資材モーダル表示用:明細一覧
    materials: [],
    editedList: [],
    headers: [
      // No
      { text: "No", value: "no", align: "right", width: "5%" },
      // 資材名
      { text: i18n.tc("label.lbl_materials"), value: "material", align: "left", width: "50%" },
      // 数量
      { text: i18n.tc("label.lbl_quantity"), value: "quantity", align: "right", width: "30%" },
    ],
    // 流通加工計画一覧ヘッダ
    headerItems: [
      // No
      {
        text: "No",
        class: "listHeader",
        value: "No",
        width: "50px",
        sortable: false,
        align: "right",
      },
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "300px",
        align: "center",
        class: "asta",
        sortable: false,
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_lot"),
        value: "lot",
        width: "220px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_serial"),
        value: "serial",
        width: "250px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_dueDate"),
        value: "dueDate",
        width: "220px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_scheduleQuantity"),
        value: "scheduleQuantity",
        width: "220px",
        align: "right",
        class: "asta",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_capacityQty"),
        value: "capacityQty",
        width: "220px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_numberOfPackages"),
        value: "numberOfPackages",
        width: "220px",
        align: "right",
        class: "asta",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_materials"),
        value: "materials",
        align: "center",
        width: "50px",
      },
      { text: i18n.tc("btn.btn_delete"), value: "deleteData", width: "50px", align: "center" },
      {
        text: i18n.tc("label.lbl_lotSubkey1"),
        value: "lotSubkey1",
        width: "220px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_lotSubkey2"),
        value: "lotSubkey2",
        width: "220px",
        align: "center",
      },
    ],
    // 品番リスト
    productCdList: [],
    // 品名リスト
    productNameList: [],
    // 付帯品番リスト
    accessoryCdList: [],
    // 出荷先リスト
    shippingList: [],
    // 計画日付
    planDayCal: "",
    // 一覧削除用
    deleteList: [],
    // 共通部品用
    // シンプルダイアログ表示用
    infoDialog: {
      title: "",
      message: "",
      isOpen: false,
      distProcPlanEditFlg: false,
      firstPageFlag: false,
      outsideClickNotCloseFlg: false,
      okAction: () => {},
    },

    // コンファームダイアログ表示用
    ConfirmDialog: {
      screenFlag: false,
      changeFlag: false,
      message: "",
      redMessage: "",
      title: "",
      isOpen: false,
      okAction: () => {},
    },

    // ローディング画面表示用
    loadingCounter: 0,

    // 資材確認ダイアログ表示用
    selectMaterialsDialogIsShow: false,

    // 不足分の変数※一時作成
    freeCostDivList: "",
    updateDialogMessage: false,
    // 修正コントロールフラグ
    deleteControlFlg: false,
    // 警告ダイアログメッセージ
    backMessage: "",
    errorFlg: true,
    inputSumList: [],
    popNewProductCd: "",
    popNewProductNm: "",
    popInboundUnitQuantity: "",
    openMenu: false,
    isDisabledFreeReason: false,
    shippingToName: "",
    toBaseCd: "",
    supplierList: [],
  }),

  updated() {
    // 画面が再描画されたとき
    if (this.moveFocusFlag) {
      this.setCursor();
      this.moveFocusFlag = false;
    }
  },

  created() {
    //一覧画面から受け取る値
    // 取引先
    this.suppliersSelected = this.$route.params.detailsList.headerList.searchSuppliersSelected;
    // 取引先リスト
    this.supplierList = this.$route.params.detailsList.supplierList;
    // 出荷先名
    this.shippingSelected = this.$route.params.detailsList.shippingNm;
    // 加工日
    this.planDay = this.$route.params.detailsList.inOutScheduleDate;
    //加工日(TO)
    this.planDayCal = dateTimeHelper.dateCalc(
      dateTimeHelper.convertJST(this.$route.params.detailsList.inOutScheduleDate)
    );
    // 計画区分
    this.planningSection = this.$route.params.detailsList.headerList.searchPlanningSection;
    //伝票No
    this.voucherNo = this.$route.params.detailsList.inOutListNo;
    //資材引当結果
    this.allocationResult = this.$route.params.detailsList.headerList.searchAllocationResult;
    //作成日
    this.arrCreatedDateFrom = this.$route.params.detailsList.headerList.searcharrCreatedDateFrom;
    // 取引先名
    this.suppliersNm = this.$route.params.detailsList.suppliersSelectedName;
    //伝票SID
    this.inOutListSub = this.$route.params.detailsList.inOutListSub;
    //作業日
    this.inOutScheduleDate = this.$route.params.detailsList.inOutScheduleDate;
    //伝票No
    this.inOutListNo = this.$route.params.detailsList.inOutListNo;
    //品質区分
    this.arrivalStatusSelected = this.$route.params.detailsList.arrivalStatusSelected;
    //責任区分
    this.isRes = this.$route.params.detailsList.isRes;
    //有償無償
    this.isPaid = this.$route.params.detailsList.isPaid;
    //理由区分
    this.freeReasonSelected = this.$route.params.detailsList.freeReasonSelected;
    //備考欄
    this.remarksTextbox = this.$route.params.detailsList.remarksTextbox;
    //処理区分
    this.processDiv = this.$route.params.detailsList.processDiv;
    // //入出荷先
    // this.fromToSid = this.$route.params.detailsList.fromToSid;
    // 出荷先
    this.shippingTo = this.$route.params.detailsList.toSid;
    // 出荷先名称
    this.shippingToName = this.$route.params.detailsList.toName;
    // 出荷先拠点CD
    this.toBaseCd = this.$route.params.detailsList.toBaseCd;
    //輸送会社名
    this.carrierCompName = this.$route.params.detailsList.carrierCompName;
    //路線伝票名
    this.deliveryTraderName = this.$route.params.detailsList.deliveryTraderName;
    //遷移元画面
    this.screenName = this.$route.params.detailsList.screenName;
  },

  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定(流通加工一覧から値受け取る)
     */
    init() {
      // 流通加工を取得します。
      this.getProcessiongList();
      // コードマスタを取得します。
      this.getMstCode();
      // 出荷一覧を取得
      this.initShippingDropdown();
    },

    /**
     * 出荷先ドロップダウンを初期化する
     */
    initShippingDropdown() {
      this.shippingList = [
        {
          text: this.$route.params.detailsList.shippingNm,
          value: this.$route.params.detailsList.toSid,
          name: this.$route.params.detailsList.toName,
          baseCd: this.$route.params.detailsList.toBaseCd,
        },
      ];
      this.shippingSelected = this.$route.params.detailsList.toSid;
      this.changeArrival(this.shippingSelected);
    },

    /**
     * コードマスタを取得します。
     */
    getMstCode() {
      // ローディング画面の表示をONにします。
      this.loadingCounter = 1;

      // 有償無償区分一覧を取得します。
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);

      // 責任区分一覧を取得します。
      const blameList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);

      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.IN_REASON_DIV);

      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);

      // 非同期処理を開始します。
      Promise.all([freeCostList, blameList, reasonList, qualityList])
        // 成功時の処理です。
        .then((result) => {
          // 有償無償区分一覧を設定します。
          this.freeCostDivList = result[0];

          // 責任区分一覧の最初の"なし"を削除します。
          result[1].splice(0, 1);

          // 責任区分一覧を設定します。
          this.blameDivList = result[1];

          // 理由コード一覧を設定します。
          this.freeReasonList = result[2];

          // 品質区分の取得
          this.qualityAutoCompleteList = result[3];
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        // 成功・失敗に関わらず実行される処理です。
        .finally(() => {
          // ローディング画面の表示をOFFにします。
          this.loadingCounter = 0;
        });
    },

    /**
     * 加工計画修正削除画面：加工計画明細を取得します。
     */
    getProcessiongList() {
      // ローディング画面の表示をONにします。
      this.loadingCounter = 1;

      // GetAPIリクエスト用のConfigを生成します。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 取引先SID
      config.params.clientSid = this.suppliersSelected;

      // 伝票SID
      config.params.outListSid = this.inOutListSub;

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 入荷予定を取得します。
          .secureGet(appConfig.API_URL.DST_LIST_DETAIL, config)
          // 成功時の処理です。
          .then((response) => {
            // レスポンスをログに出力します。
            // console.debug("getProcessiongList() Response", response);

            // レスポンスからJSONオブジェクトを取得します。
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // JSONオブジェクトから入荷予定明細を取得します。
            const apiData = jsonData.resIdv.processingScheduleList;

            // 処理結果コードが"000"（Success・Retry）の場合の処理です。
            if (jsonData.resCom.resComCode == "000") {
              // JSONオブジェクトから入荷予定を取得します。
              for (var j = 0; j < apiData.length; j++) {
                // 入荷予定明細を取得します。
                let apiDataItem = apiData[j];

                // マップを生成します。
                let inputListItem = {};

                // リストを生成します。
                let list = [];

                // リストに商品情報
                list.push({
                  text:
                    `${apiDataItem.itemCd}` +
                    ` ${apiDataItem.itemSubCd}` +
                    `（${apiDataItem.itemName}）`,
                  value: apiDataItem.itemStandardSid,
                  name: apiDataItem.itemName,
                  code: apiDataItem.itemCd,
                  isNew: false,
                });
                // 商品情報
                inputListItem.productList = list;
                // 予定削除フラグ
                inputListItem.psc1DeleteFlg = apiDataItem.psc1DeleteFlg;
                // 予定更新日時
                inputListItem.psc1UpdateDatetime = apiDataItem.psc1UpdateDatetime;
                // 予定明細削除フラグ
                inputListItem.psc2DeleteFlg = apiDataItem.psc2DeleteFlg;
                // 予定明細更新日時
                inputListItem.psc2UpdateDatetime = apiDataItem.psc2UpdateDatetime;
                // 伝票SID
                inputListItem.outListSid = apiDataItem.outListSid;
                // 伝票明細SID
                inputListItem.outListSubSid = apiDataItem.outListSubSid;
                // 伝票明細No
                inputListItem.outListSubNo = apiDataItem.outListSubNo;
                // No
                inputListItem.No = j + 1;
                // 品番
                inputListItem.itemCd = apiDataItem.itemCd;
                // 品名
                inputListItem.itemName = apiDataItem.itemName;
                // 商品標準SID
                inputListItem.productCd = apiDataItem.itemStandardSid;
                // 品番/品名
                inputListItem.productHint =
                  apiDataItem.itemCd +
                  " " +
                  apiDataItem.itemSubCd +
                  "（" +
                  apiDataItem.itemName +
                  "）";
                // 商品取得API
                const itemsBiz = getParameter.getItemsBizAddName2(
                  this.suppliersSelected,
                  dateTimeHelper.convertUTC(this.planDay),
                  apiDataItem.itemCd
                );

                Promise.all([itemsBiz])
                  .then((result) => {
                    // 画面の初期値を設定します。
                    inputListItem.productList = result[0];
                  })
                  .catch((ex) => {
                    this.infoDialog.message = ex;
                    this.infoDialog.title = appConfig.DIALOG.title;
                    this.infoDialog.isOpen = true;
                    this.infoDialog.firstPageFlag = true;
                  })
                  .finally(() => {
                    // ローディング画面表示OFF
                    this.loadingCounter = 0;
                  });

                inputListItem.productList = list;

                // 商品検索
                inputListItem.search = apiDataItem.itemCd + "（" + apiDataItem.itemName + "）";
                // 付属品番
                inputListItem.accessoryCd = apiDataItem.itemSubCd;
                // ロット
                const lot = commonUtil.convertLotNo(apiDataItem.lotNo);
                inputListItem.lot = lot.original.lot; // ロット
                inputListItem.serial = lot.original.serial; // S/N
                inputListItem.dueDate = lot.original.dueDate; // 期限日
                inputListItem.lotSubkey1 = lot.original.lotSubkey1; // 付属キー1
                inputListItem.lotSubkey2 = lot.original.lotSubkey2; // 付属キー2

                inputListItem.lotFlg = apiDataItem.lotFlg;
                inputListItem.serialFlg = apiDataItem.serialFlg;
                inputListItem.dueDateFlg = apiDataItem.dueDateFlg;
                inputListItem.lotSubkey1Flg = apiDataItem.lotSubkey1Flg;
                inputListItem.lotSubkey2Flg = apiDataItem.lotSubkey2Flg;

                // 出荷予定数
                let productQtyNum = apiDataItem.shipScheduleQuantity;
                inputListItem.scheduleQuantity = String(
                  commonUtil.formatToCurrency(Number(productQtyNum))
                );
                // 収容数数
                let shipUnitNum = apiDataItem.shipUnitQuantity;
                inputListItem.capacityQty = String(
                  commonUtil.formatToCurrency(Number(shipUnitNum))
                );
                // 梱包数
                let shipPackingNum = apiDataItem.shipPackingQuantity;
                inputListItem.numberOfPackages = String(
                  commonUtil.formatToCurrency(Number(shipPackingNum))
                );
                // 明細更新日時
                inputListItem.updateDatetime = apiDataItem.updateDatetime;
                // 品質区分
                inputListItem.qualityDiv = apiDataItem.qualityDiv;
                // 引当済のフラグ
                inputListItem.deleteControlFlg = apiDataItem.deleteControlFlg;

                if (apiDataItem.deleteControlFlg == "02") {
                  this.deleteControlFlg = true;
                }
                // 完了フラグ
                inputListItem.completeFlg = apiDataItem.completeFlg;
                inputListItem.message = "";

                // マップをinputListに追加
                this.inputList.push(inputListItem);
              }

              // inputListのサイズが0件だった場合、修正登録ボタンが非活性
              if (apiData.length == 0) {
                this.deleteControlFlg = true;
              }

              // タスクを終了します（成功）。
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              // タスクを終了します（失敗）。
              reject();
            }
          })
          // 失敗時の処理です。
          .catch((ex) => {
            console.error("getProcessiongList() Resolve", resolve);
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            // タスクを終了します（失敗）。
            reject();
          })
          // 成功・失敗に関わらず実行される処理です。
          .finally(() => {
            // ローディング画面の表示をOFFにします。
            this.loadingCounter = 0;
          });
        // 成功時の処理です。
      }).then(() => {
        var totalNum = 0;
        this.totalCount = 0;

        for (var j = 0; j < this.inputList.length; j++) {
          var proNum = commonUtil.zen2han(this.inputList[j].scheduleQuantity).replaceAll(",", "");
          // 各行の予定数の合計を計算
          totalNum = Number(totalNum) + Number(proNum);
        }
        // カンマ編集
        this.totalCount = commonUtil.formatToCurrency(Number(totalNum));
      });
    },

    /**
     * 加工計画登録画面：出荷元コードリストボックス取得処理
     */
    setSrhArrival() {
      this.shippingAddress = commonFunction.getListSetName(
        this.shippingList,
        this.shippingSelected
      ); // 資材モダール用：出荷先名
      return commonFunction.getListSetName(this.shippingList, this.shippingSelected);
    },

    /**
     * 加工計画修正・削除画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * プルダウンチェンジイベント
     */
    changeArrival(value) {
      // 既存品番/品名を選択時
      let val = this.shippingList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintArrival = val.text;
        this.getShippingSelected = val.text;
        // this.shippingSelected = this.$route.params.detailsList.toSid;
      }
      this.shippingList = this.shippingList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintArrival = "";
      } else {
        // 処理なし
      }
    },

    /**
     * 基準日を－１日します。
     */
    prevDate() {
      let date = new Date(this.planDayCal);
      this.planDayCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    /**
     * 基準日を＋１日します。
     */
    nextDate() {
      let date = new Date(this.planDayCal);
      this.planDayCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.planDayCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.planDayCal = null;
      }
    },

    /**
     * 行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);

      // 削除したリストを退避する。
      if (item.isAdd !== true) {
        this.deleteList.push(this.inputList[this.editedIndex]);
      }
      this.inputList.splice(this.editedIndex, 1);

      // 削除後、No修正
      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = i + 1;
      }
      // console.debug("行削除");
      var proQty = commonUtil.zen2han(item.scheduleQuantity).replaceAll(",", "");
      var totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");

      // 予定数合計から削除したレコードの数を引く
      this.totalCount = Number(totalNum) - Number(proQty);
      // カンマ編集
      this.totalCount = commonUtil.formatToCurrency(Number(this.totalCount));

      this.$refs["cursorProcd" + this.editedIndex].resetValidation();
      this.$refs["capacityQtyCheck" + this.editedIndex].resetValidation();
      this.$refs["scheduleQuantityCheck" + this.editedIndex].resetValidation();
      this.$refs["numberOfPackagesCheck" + this.editedIndex].resetValidation();
      this.$refs["lotCheck" + this.editedIndex].resetValidation();
      this.$refs["accessoryCdCheck" + this.editedIndex].resetValidation();
    },

    /**
     * 数値のみ入力
     */
    deletedueDateOtherChar(item) {
      item.dueDate = item.dueDate.replace(/\D/g, "");
    },

    /**
     * 計画数 カンマ付与処理および梱包数自動算出
     */
    changeScheduleQuantity(item, index) {
      var scheduleQuantity = commonUtil.zen2han(item.scheduleQuantity).replaceAll(",", "");
      scheduleQuantity = scheduleQuantity.replace(/[^0-9]/gi, "");
      if (scheduleQuantity == "-0") {
        scheduleQuantity = "0";
      }
      if (scheduleQuantity.length > 9) {
        scheduleQuantity = scheduleQuantity.substring(0, 9);
      }

      // 選択した商品の出庫単位数を取得する
      let capacityQty = this.inputList[index].capacityQty;
      capacityQty = commonUtil.formatToCurrency(Number(capacityQty));

      if (!isNaN(Number(scheduleQuantity))) {
        this.inputList[index].scheduleQuantity = commonUtil.formatToCurrency(
          Number(scheduleQuantity)
        );
        if (0 < capacityQty) {
          // 計画予定数が入力されている場合
          this.inputList[index].numberOfPackages = commonUtil.formatToCurrency(
            Math.ceil(scheduleQuantity / capacityQty)
          );
          // カンマ編集して数量にセット
        } else {
          this.inputList[index].capacityQty = "0";
          this.inputList[index].numberOfPackages = "0";
        }
      }
      var totalNum = 0;
      this.totalCount = 0;
      if (!isNaN(Number(scheduleQuantity)) || Number(totalNum)) {
        for (var j = 0; j < this.inputList.length; j++) {
          var proNum = commonUtil.zen2han(this.inputList[j].scheduleQuantity).replaceAll(",", "");

          // 各行の予定数の合計を計算
          totalNum = Number(totalNum) + Number(proNum);
        }
        // カンマ編集
        this.totalCount = commonUtil.formatToCurrency(Number(totalNum));
      }
    },
    /**
     * 収容数 カンマ付与処理および梱包数自動算出
     */
    changeCapacityQty(item, index) {
      var capacityQty = commonUtil.zen2han(item.capacityQty).replaceAll(",", "");
      capacityQty = capacityQty.replace(/[^0-9]/gi, "");
      if (capacityQty == "-0") {
        capacityQty = "0";
      }
      if (capacityQty.length > 9) {
        capacityQty = capacityQty.substring(0, 9);
      }
      if (!isNaN(Number(capacityQty))) {
        this.inputList[index].capacityQty = commonUtil.formatToCurrency(Number(capacityQty));
      }
      // 選択した商品の予定数を取得する
      let productQty = this.inputList[index].scheduleQuantity;
      productQty = commonUtil.formatToCurrency(Number(productQty));

      if (!isNaN(Number(capacityQty))) {
        this.inputList[index].capacityQty = commonUtil.formatToCurrency(Number(capacityQty));
        if (0 < capacityQty) {
          // 計画予定数が入力されている場合
          this.inputList[index].numberOfPackages = commonUtil.formatToCurrency(
            Math.ceil(productQty / capacityQty)
          );
          // カンマ編集して数量にセット
        } else {
          this.inputList[index].capacityQty = "0";
          this.inputList[index].numberOfPackages = "0";
        }
      }
    },

    /**
     * 数量カンマ付与処理
     */
    changeQuantity(quantity) {
      var resultQuantity = commonUtil.zen2han(quantity).replaceAll(",", "");
      resultQuantity = resultQuantity.replace(/[^0-9]/gi, "");
      if (resultQuantity.length > 9) {
        resultQuantity = resultQuantity.substring(0, 9);
      }
      if (!isNaN(Number(resultQuantity))) {
        return commonUtil.formatToCurrency(Number(resultQuantity));
      }
      return resultQuantity;
    },

    /**
     * 梱包数算出
     */
    changeNumberOfPackages(item, index) {
      var numberOfPackages = commonUtil.zen2han(item.numberOfPackages).replaceAll(",", "");
      numberOfPackages = numberOfPackages.replace(/[^0-9]/gi, "");
      if (numberOfPackages == "-0") {
        numberOfPackages = "0";
      }
      if (numberOfPackages.length > 9) {
        numberOfPackages = numberOfPackages.substring(0, 9);
      }
      if (!isNaN(Number(numberOfPackages))) {
        this.inputList[index].numberOfPackages = commonUtil.formatToCurrency(
          Number(numberOfPackages)
        );
      }
    },

    /**
     * 数値入試力時カンマ排除処理
     */
    clickPop(val) {
      let myName = event.target;
      let pop = val || "";
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },

    /**
     * 戻るボタン処理
     */
    backbtn() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    /**
     * 一覧画面へ渡す値を設定
     */
    updateOk() {
      if (this.errorFlg) {
        this.updateDialogMessage = false;
        this.checkFlg = true;
        this.$router.push({
          name: appConfig.SCREEN_ID.P_TMP_903,
          params: {
            // 流通加工一覧検索条件
            headerList: this.$route.params.detailsList.headerList,
            backPage: "detailsList",
          },
        });
      } else {
        this.updateDialogMessage = false;
        this.errorFlg = true;
      }
    },

    /**
     * 戻るボタンダイアログ画面：NG処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 行追加処理
     */
    add() {
      this.isDoAddButton = true;

      if (this.inputList.length != 0) {
        this.focusIdx = this.inputList[this.inputList.length - 1].No;
      } else {
        this.focusIdx = 0;
      }

      for (var i = 0; i < 10; i++) {
        this.defaultItem.No = this.focusIdx + i + 1;
        this.inputList.push(Object.assign({}, this.defaultItem));
      }
      // フォーカス移動フラグをONに
      this.moveFocusFlag = true;
      // xミリ秒待機したのちに、追加ボタンをenabledに
      setTimeout(this.enabledAddButton, 500);
      // console.debug("行追加");
    },

    /**
     * 追加ボタンを有効化する
     */
    enabledAddButton() {
      this.isDoAddButton = false;
    },

    /**
     * 入力項目バリデーション
     */
    validationRow(item) {
      var editedIndex = this.inputList.indexOf(item);

      var result =
        this.$refs["cursorProcd" + editedIndex].validate() ||
        this.$refs["scheduleQuantityCheck" + editedIndex].validate() ||
        this.$refs["numberOfPackagesCheck" + editedIndex].validate();

      return result;
    },

    /**
     * 流通加工修正削除画面: 資材確認ダイアログを開く
     */
    openSelectMaterialsDialog(item) {
      // モーダル初期化
      this.productCd = ""; // 品番
      this.itemStandardSid = ""; // 商品標準SID
      this.productCdAndName = ""; // 品名
      this.accessoryNumber = ""; // 付属品番
      this.quantity = ""; // 数量
      this.packagingQuantity = ""; // 梱包数
      this.materials = []; // 資材一覧を初期化
      this.message = ""; // エラー
      // 予定数、収容数、梱包数入力チェック
      if (!item.productCd || !item.scheduleQuantity || !item.numberOfPackages) {
        return;
      }
      if (!this.deleteControlFlg && !this.validationRow(item)) {
        return;
      }
      // 資材確認ダイアログ用のデータを作成する
      this.productCd = item.productList.find((data) => data.value === item.productCd).code; // 品番
      this.itemStandardSid = item.productCd; // 商品標準SID
      this.productCdAndName = item.productHint; // 品名
      this.accessoryNumber = item.accessoryCd; // 付属品番
      this.quantity = item.scheduleQuantity; // 数量
      this.packagingQuantity = item.numberOfPackages; // 梱包数

      // 資材取得APIを呼び出す
      this.getMaterials();
      // モーダル表示フラグ
      this.selectMaterialsDialogIsShow = true;
    },

    /**
     * 資材モダール：資材取得API(GET)
     */
    getMaterials() {
      this.message = "";
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      // 商品標準SID
      config.params.itemStandardSid = this.itemStandardSid;

      // console.debug("getMaterials() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.DST_MATERIAL_LIST, config)
          .then((response) => {
            // console.debug("getMaterials() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            const apiData = jsonData.resIdv.processingMaterial;
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              // レスポンスで取得した明細行データをリストに詰める
              for (var i = 0; i < apiData.length; i++) {
                list.push({
                  // NO
                  no: i + 1,
                  // 資材品名
                  material: apiData[i].itemName,
                  // 数量
                  quantity: this.matchMaterialQuantity(apiData[i].composeQuantity),
                  // 空フラグ
                  colorFlg: false,
                });
              }
              // テーブルデータリストに明細行データを設定
              this.materials = list;
              for (var j = 0; j < this.materials.length; j++) {
                if (this.materials[j].quantity.replaceAll(",", "").length > 9) {
                  this.materials[j].colorFlg = true;
                }
              }
              if (this.materials.filter((data) => data.colorFlg == true).length > 0) {
                this.message = messsageUtil.getMessage("P-DST-001_005_E");
              }

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 資材確認モダール：数量を精算
     */
    matchMaterialQuantity(val) {
      let materialQuantity = Number(commonUtil.zen2han(val).replaceAll(",", ""));
      let itemQuantity = Number(commonUtil.zen2han(this.quantity).replaceAll(",", ""));

      materialQuantity = materialQuantity * itemQuantity;

      return commonUtil.formatToCurrency(Number(materialQuantity));
    },

    /**
     * 資材確認モーダルを閉じる
     */
    closeDialog() {
      this.selectMaterialsDialogIsShow = false;
    },

    /**
     * 流通加工計画登録画面：商品コードリストボックス取得処理
     */
    checkProductCd(value, index) {
      return this.inputList[index].productHint;
    },

    changeProductNm(value, item, index) {
      // 既存品番/品名を選択時()
      let val = this.inputList[index].productList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        item.productNm = val.name;
        item.productHint = val.text;
        item.search = val.text;
        item.productList = item.productList.filter((v) => v.value == value);
        item.capacityQty = commonUtil.formatToCurrency(
          val.outboundUnitQuantity ? val.outboundUnitQuantity : 0
        );
        // ロット
        item.lotFlg = val.lotFlg; // ロット
        item.serialFlg = val.serialFlg; // S/N
        item.dueDateFlg = val.dueDateFlg; // 期限日
        item.lotSubkey1Flg = val.lotSubkey1Flg; // 付属キー1
        item.lotSubkey2Flg = val.lotSubkey2Flg; // 付属キー2
      }
    },

    /**
     * 予定数量チェック
     */
    checkMaxAmount(scheduleQuantity, itemCd, itemList) {
      if (scheduleQuantity && itemCd) {
        var qty = Number(commonUtil.zen2han(scheduleQuantity).replaceAll(",", ""));
        var maxQty = itemList.find((data) => data.value === itemCd).maxStockValue;
        if (!isNaN(Number(qty))) {
          let isValid = maxQty <= qty;

          if (isValid) {
            return (
              commonUtil.formatToCurrency(Number(maxQty)) + i18n.tc("check.chk_underScheduleStock")
            );
          }
        }
      }
      return true;
    },

    /**
     * 加工計画修正削除前にバリエーションチェック
     */
    planAddCheck() {
      let cnt = 0;
      this.alertMessage = "";
      if (this.$refs.form.validate() && this.$refs.editedList.validate()) {
        // 明細部未入力チェック準備
        let checkList = []; // 明細部チェックリスト
        for (let i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].productCd) {
            // 明細部の品番品名が入力されている場合はリストに追加
            checkList.push(this.inputList[i].productCd);
          } else {
            // 上記以外の場合は処理なし。
          }
        }

        // 明細部の未入力チェック
        if (checkList.length <= 0) {
          this.deleteFlg = true;
          // 上記でのチェックリストが0件の場合は、警告メッセージ表示
          this.ConfirmDialog.message = messsageUtil.getMessage("P-DST-001_004_S");
          this.ConfirmDialog.isOpen = true;
          this.ConfirmDialog.screenFlag = true;
          this.ConfirmDialog.changeFlag = false;
          this.ConfirmDialog.okAction = this.planRegistion;
        } else {
          // 明細部、入力チェック実施
          for (var i = 0; i < this.inputList.length; i++) {
            if (this.inputList[i].productCd) {
              var checkPlanQtyNum = Number(
                commonUtil.zen2han(this.inputList[i].scheduleQuantity).replaceAll(",", "")
              );
              var checkCapacityQtyNum = Number(
                commonUtil.zen2han(this.inputList[i].capacityQty).replaceAll(",", "")
              );
              var checkPackNum = Number(
                commonUtil.zen2han(this.inputList[i].numberOfPackages).replaceAll(",", "")
              );

              if (checkPlanQtyNum == "" || checkPlanQtyNum == null) {
                // 計画予定数が未入力時
                cnt = i + 1;
              } else {
                // 処理なし
              }

              // 数値チェック
              if (isNaN(checkPlanQtyNum) || isNaN(checkCapacityQtyNum) || isNaN(checkPackNum)) {
                // 予定数、収容数、梱包数が数値でない時
                cnt = i + 1;
              } else {
                // 処理なし
              }

              // 整数チェック
              if (
                Number.isInteger(checkPlanQtyNum) &&
                Number.isInteger(checkCapacityQtyNum) &&
                Number.isInteger(checkPackNum)
              ) {
                // 処理なし
              } else {
                // 予定数、収容数、梱包数がが整数でない時
                cnt = i + 1;
              }

              // 範囲チェック（0未満）
              if (0 < checkPlanQtyNum && 0 < checkCapacityQtyNum && 0 < checkPackNum) {
                // 処理なし
              } else {
                // 予定数、収容数、梱包数が0未満の場合
                cnt = i + 1;
              }
            }
          }
          // 明細部、入力エラーなし（カウント0）の場合、確認メッセージ表示
          if (cnt == 0) {
            if (this.isPaid == "02") {
              this.ConfirmDialog.screenFlag = false;
              this.ConfirmDialog.changeFlag = true;
              this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_004_C");
              this.ConfirmDialog.redMessage = "無償";
              this.ConfirmDialog.title = "警告";
              this.ConfirmDialog.isOpen = true;
              this.ConfirmDialog.okAction = this.planRegistion;
            } else {
              this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
              this.ConfirmDialog.isOpen = true;
              this.ConfirmDialog.screenFlag = true;
              this.ConfirmDialog.changeFlag = false;
              this.ConfirmDialog.okAction = this.planRegistion;
            }
          } else {
            // 明細部、入力エラー時（カウント1）
            if (this.$refs.editedList.validate()) {
              // 処理なし
            } else {
              for (var j = 0; j < this.inputList.length; j++) {
                let checkPosition = String(j);
                if (!this.inputList[j].productCd) {
                  // 関係ないレコードの入力チェックエラーを解除
                  eval("this.$refs.scheduleQuantityCheck" + checkPosition + ".resetValidation()");
                  eval("this.$refs.capacityQtyCheck" + checkPosition + ".resetValidation()");
                  eval("this.$refs.numberOfPackagesCheck" + checkPosition + ".resetValidation()");
                } else {
                  // 処理なし
                }
              }
            }
          }
        }
      } else {
        // 伝票部、入力チェックエラーの場合、処理なし
      }
    },

    /**
     *  加工計画登録処理（POST)
     */
    planRegistion() {
      if (this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        // httpClientで使用する共通IFパラメータ等を作成する。
        const body = this.$httpClient.createRequestBodyConfig();
        // 共通IF項目 画面ID
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TMP_901;

        // 計画明細List
        let bodyList = [];

        for (let i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].productCd != "" && this.inputList[i].productCd != null) {
            var inputMap = {};
            // 出荷先：toSID
            inputMap.toSid = this.shippingSelected;
            // 商品標準SID
            inputMap.itemStandardSid = this.inputList[i].productCd;
            // 商品コード
            for (let m = 0; m < this.inputList[i].productList.length; m++) {
              if (this.inputList[i].productList[m].value == this.inputList[i].productCd) {
                inputMap.itemCd = this.inputList[i].productList[m].code;
                break;
              }
            }
            // 付属品番
            if (this.inputList[i].accessoryCd) {
              inputMap.itemSubCd = this.inputList[i].accessoryCd;
            } else {
              inputMap.itemSubCd = "";
            }
            // ロット
            inputMap.lotNo = this.createLot(this.inputList[i]);

            // 予定数量
            inputMap.shipScheduleQuantity = this.inputList[i].scheduleQuantity.replaceAll(",", "");
            // 収容数
            inputMap.shipUnitQuantity = this.inputList[i].capacityQty.replaceAll(",", "");
            // 梱包数
            inputMap.shipPackingQuantity = this.inputList[i].numberOfPackages.replaceAll(",", "");
            // 予定明細削除フラグ
            inputMap.deleteFlg = this.inputList[i].psc2DeleteFlg;
            // 予定明細更新日時
            inputMap.updateDatetime = this.inputList[i].psc2UpdateDatetime;
            // 伝票明細SID
            inputMap.outListSubSid = this.inputList[i].outListSubSid;
            // 伝票明細No
            inputMap.outListSubNo = this.inputList[i].outListSubNo;

            bodyList.push(inputMap);
          }
        }
        for (var x = 0; x < this.deleteList.length; x++) {
          if (
            this.deleteList[x].productCd != "" &&
            this.deleteList[x].productCd != null &&
            this.deleteList[x].outListSubSid
          ) {
            var inputMapdel = {};
            // 出荷先：toSID
            inputMapdel.toSid = this.shippingSelected;
            // 商品標準SID
            inputMapdel.itemStandardSid = this.deleteList[x].productCd;
            // 商品コード
            for (let m = 0; m < this.deleteList[x].productList.length; m++) {
              if (this.deleteList[x].productList[m].value == this.deleteList[x].productCd) {
                inputMapdel.itemCd = this.deleteList[x].productList[m].code;
                break;
              }
            }
            // 付属品番
            if (this.deleteList[x].accessoryCd) {
              inputMapdel.itemSubCd = this.deleteList[x].accessoryCd;
            } else {
              inputMapdel.itemSubCd = "";
            }
            // ロット
            if (this.deleteList[x].lot) {
              inputMapdel.lotNo = this.deleteList[x].lot;
            } else {
              inputMapdel.lotNo = "";
            }
            // 予定数量
            inputMapdel.shipScheduleQuantity = this.deleteList[x].scheduleQuantity.replaceAll(
              ",",
              ""
            );
            // 収容数
            inputMapdel.shipUnitQuantity = this.deleteList[x].capacityQty.replaceAll(",", "");
            // 梱包数
            inputMapdel.shipPackingQuantity = this.deleteList[x].numberOfPackages.replaceAll(
              ",",
              ""
            );
            // 予定明細削除フラグ
            inputMapdel.deleteFlg = "1";
            // 予定明細更新日時
            inputMapdel.updateDatetime = this.deleteList[x].psc2UpdateDatetime;
            // 伝票明細SID
            inputMapdel.outListSubSid = this.deleteList[x].outListSubSid;
            // 伝票明細No
            inputMapdel.outListSubNo = this.deleteList[x].outListSubNo;

            bodyList.push(inputMapdel);
          }
        }
        if (this.deleteFlg == true) {
          // 計画予定削除フラグ
          body.reqIdv.deleteFlg = 1;
          // 伝票SID
          body.reqIdv.outListSid = this.deleteList[0].outListSid;
          // 計画予定更新日時
          body.reqIdv.updateDatetime = this.deleteList[0].psc1UpdateDatetime;
        } else {
          // 計画予定削除フラグ
          body.reqIdv.deleteFlg = this.inputList[0].psc1DeleteFlg;
          // 伝票SID
          body.reqIdv.outListSid = this.inputList[0].outListSid;
          // 計画予定更新日時
          body.reqIdv.updateDatetime = this.inputList[0].psc1UpdateDatetime;
        }
        // 取引先SID
        body.reqIdv.clientSid = this.suppliersSelected;
        // 計画No
        body.reqIdv.outListNo = this.voucherNo;
        // 計画日
        body.reqIdv.outScheduleDate = dateTimeHelper.convertUTC(this.planDay);
        // 有償無償区分
        body.reqIdv.freeCostDiv = this.isPaid;
        // 責任区分
        body.reqIdv.blameDiv = this.isRes;
        // 無償理由
        body.reqIdv.reasonCd = this.freeReasonSelected;
        // 備考
        body.reqIdv.remarks = this.remarksTextbox;
        // 出荷先：toSID
        body.reqIdv.toSid = this.shippingSelected;
        // FromSid
        body.reqIdv.fromSid = sessionStorage.getItem("sales_office_sid");
        // 品質区分
        body.reqIdv.qualityDiv = this.arrivalStatusSelected;
        // 加工明細リスト
        body.reqIdv.processingScheduleList = bodyList;
        // console.debug("planRegistion() Body", body);

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.DST_PROCESS_PLAN_ADD, body, appConfig.APP_CONFIG)
            .then((response) => {
              // console.debug("planRegistion() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = false;
                this.infoDialog.outsideClickNotCloseFlg = true;
                // 画面遷移ボタン
                this.infoDialog.distProcPlanEditFlg = true;
                resolve();
              } else {
                // エラーメッセージをpopupのmessageに格納
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                this.infoDialog.distProcPlanEditFlg = false;
                this.infoDialog.outsideClickNotCloseFlg = false;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex.message;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.distProcPlanEditFlg = false;
              this.infoDialog.outsideClickNotCloseFlg = false;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    /**
     * 分割されているlotNoの構築
     */
    createLot(item) {
      return commonUtil.createLotNo(
        item.lot,
        item.serial,
        item.dueDate,
        item.lotSubkey1,
        item.lotSubkey2
      );
    },

    /**
     * カーソル位置処理
     */
    setCursor() {
      let listLength = this.inputList.length;

      let cursolPosition = String(listLength - 10);
      eval("this.$refs.cursorProcd" + cursolPosition + ".focus()");
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },

    dialog(val) {
      val || this.close();
    },

    planDayCal(val) {
      this.planDay = this.formatDate(val);
    },

    isPaid: function (newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
      }
    },

    /**
     * 出荷先取得
     */
    getShippingSelected(val) {
      if (val ?? false) {
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          this.shippingList = [];
          this.shippingSelected = "";
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintArrival) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // To（出荷先）取得
          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.FROMTO,
            dateTimeHelper.convertUTC(),
            undefined,
            this.$route.params.detailsList.officeCd,
            val
          );
          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。
              this.shippingList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.shippingList.length = 0;
          this.shippingList = [];
        }
      }
    },

    /**
     * 品質区分変更
     */
    arrivalStatusSelected: function (newVal) {
      if (newVal == "02") {
        this.isDisabledBlame = false;
        this.isRes = this.isResBadValue;
        this.isResBadCount++;
      } else {
        if (this.isResBadCount > 0) {
          this.isResBadValue = this.isRes;
          this.isRes = "01";
          this.isDisabledBlame = true;
        }
      }
    },

    inputList: function (inputL) {
      inputL.forEach((value) => {
        if (value.isWatchAdded === void 0) {
          this.$watch(
            () => value.search,
            (val) => {
              if (val ?? false) {
                // 取引先が選択されてない場合、処理しない
                if (this.suppliersSelected == null || this.suppliersSelected == "") {
                  return;
                }
                // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                if (val == value.productHint) {
                  return;
                }

                // 仮登録の場合変更しない
                if (value.productCd == "product0") {
                  return;
                }
                // クリア処理
                value.productCd = "";
                value.productHint = "";
                value.productNm = "";
                value.productHint = "";
                value.isNew = false;
                value.accessoryCd = null;
                value.scheduleQuantity = null;
                value.lot = null;
                value.numberOfPackages = null;
                value.capacityQty = null;

                // 入力桁数が定義した数以上の場合検索処理
                if (val.length >= appConfig.CNTCHARITEM) {
                  const itemsBiz = getParameter.getItemsBizAddName2(
                    this.suppliersSelected,
                    dateTimeHelper.convertUTC(this.planDay),
                    val
                  );

                  Promise.all([itemsBiz])
                    .then((result) => {
                      // 画面の初期値を設定します。
                      value.productList = result[0];
                      // console.debug("inputList: function (inputL) Result[0]", result[0]);
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.infoDialog.isOpen = true;
                      this.infoDialog.firstPageFlag = true;
                    })
                    .finally(() => {
                      // ローディング画面表示OFF
                      this.loadingCounter = 0;
                    });
                } else {
                  value.productList.length = 0;
                }
              }
            }
          );
        }
        value.isWatchAdded = true;
      });
    },
  },

  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  pointer-events: none;
}

.txt-singles ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
// 備考欄
.txt-remarks {
  padding-top: 0px;
  padding-right: 20px;
  padding-bottom: 0px;
  padding-left: 0px;
}

//新製品登録時、色変更
.isD {
  background-color: $color-warning !important;
  //background-color: $color-error !important;
}
.padding-bottom {
  margin-bottom: 20px;
}
// 数値項目右寄せ
::v-deep .number-align-right input {
  text-align: right;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
#lbl-user-name {
  // font-size: $menu-fontsize !important;
  font-weight: bold;
  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../assets/img/user_menu_icon.png");
    background-size: contain;
    vertical-align: middle;
  }
}
#listSumDataSpecial {
  ::-webkit-scrollbar-thumb {
    background: red;
  }

  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child {
      background-color: #eee;
      height: 0rem;
    }

    // グループヘッダー
    .v-row-group__header {
      background-color: #dde5f0;
      height: 0px;
    }

    // データがありません時のスタイル
    .v-data-table__empty-wrapper {
      background-color: #ffffff;
    }

    // テーブルデータ部分
    td {
      font-size: large;
      overflow-x: hidden;

      &:nth-child(1) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(2) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(3) {
        text-align: right;
        width: 11%;
      }

      &:nth-child(4) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(5) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(6) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(7) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(8) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(9) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(10) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(11) {
        text-align: right;
        width: 5%;
      }
    }
  }

  white-space: nowrap;
  pointer-events: none;
  font-weight: 700;

  // overflow-y: hidden;
}
// 資材モダール：テーブルスタイル
#materialsListData {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child(odd) {
      background-color: #dde5f0;
      height: 2rem;
    }
    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2rem !important;
      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    //テーブルデータ部分
    td {
      font-size: large;
    }
  }
}
//検索条件：プルダウン
.search-autocomplete-supplier {
  width: 24rem;
}

.tableData {
  border-collapse: collapse;
}
</style>
